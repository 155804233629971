import HearthIcon from "../../assets/Heart.svg"
import SectionTitle from "../SectionTitle/SectionTitle"

export default function Standpunten() {

  const OpenMore = () => {
   document.getElementById('more').classList.remove('hidden')
   document.getElementById('moreButton').classList.add('hidden')
   document.getElementById('more').classList.add('grid')
  }

  return (
    <>
      <section className="sectionSize bg-white" id="standpunten">
        <SectionTitle title="Standpunten" />
        <div className="md:grid md:grid-cols-2 md:grid-rows-2">
          <div className="flex items-start font-medium my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Migratie en sluiting Ter Apel en COA</h3>
              <p>
                In onze visie zijn wij een tolerant en gastvrij land. Wij maken geen onderscheid tussen kleur of geloof, iedereen is ons even lief. Daar staat democratie voor. Maar de toestroom van allerlei soorten migranten naar ons land maakt dat ons land te vol is geworden en velen met ons DNA geen woonplek meer kunnen vinden. Onacceptabel!
              </p>
            </div>
          </div>
          <div className="flex items-start font-medium my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Europa</h3>
              <p>
                Ook is het in onze visie verstandig het proces te beginnen de Europese Unie om te vormen (te ontvlechten) tot een federatie, zoals het oorspronkelijk ook is begonnen.  Alle lidstaten hun eigen soevereiniteit, zonder Unionistische inmenging en vooral gericht op samenwerking en (bi)laterale en of mediale afspraken).
                Geen wet- en regelgeving meer vanuit Europa. En geen geld meer naar Europa.
                Onze welvaart hebben we samen met ons eigen DNA verkregen en het moet niet de bedoeling zijn dit ons te laten niet afpakken.
                GEEN Europese digitale munt. GEEN inbreuk op privacy !
                Desnoods een Nexit !
                De besparing ad. ca. € 30 miljard zetten we in voor ons eigen DNA   </p>
            </div>
          </div>

          <div className="flex items-start font-medium my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Ouderen</h3>
              <p>
                Ouderen met een AOW-uitkering die gehuwd zijn of gaan samenwonen worden niet meer gekort. Het is niet uit te leggen dat we willen dat iedereen gezond leeft en als ze ouder worden vervolgens korten op hun opgebouwde uitkeringsrechten. De kosten van deze operatie zouden ca. 2 miljard bedragen.
                Realiseren van centrale woonvoorzieningen voor ouderen.
                Verplichte (jaarlijkse) pensioenindexatie van het ingelegde geld. </p>
            </div>
          </div>


          <div className="flex items-start font-medium my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Klimaat en natuur</h3>
              <p>
                Op alle klimaatplannen willen wij een rem. We gaan meeliften op wat de rest van de wereld doet. Dat er verandering nodig is, daar staan wij achter, echter niet in een tempo zoals voorgesteld. Alles wat nodig is zal worden uit gefaseerd, echter in lijn met het wereldresultaat.
                Door Europa federatief te maken is de discussie over stikstof overbodig. Dat neemt niet weg dat we onze natuur niet moeten beschermen. Echter alle regels gaan van tafel. We zullen in de Denktanks de discussies aangaan met de volgende generatie. (een voorlopige besparing van ca. € 60 miljard).
                Het is niet verkeerd en zelfs noodzakelijk onze natuur te willen behouden en waar nodig te herstellen, terwijl we tegelijkertijd het mooie van de natuur qua “menselijk gender” willen verstoren en/of openbreken. </p>
            </div>
          </div>
          </div>
    <div className="hidden md:grid-cols-2 md:grid-rows-2" id="more">
          <div className="flex items-start font-montserrat my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Zorg</h3>
              <p>
                De eigen bijdrage in de zorg (€ 385) zal worden afgeschaft. De administratieve -en regeldruk in de zorg zal naar beneden gaan (dus ook de premie). Bij volledig gebruik van het eigen risico zou dat ca. € 5,50 miljard kosten. Meer tijd voor handen aan het bed en voor de patiënt. Kosten van de zorg zullen worden genormeerd. Zowel voor de zorgverleners -verzekeraars als ook voor farmaceuten. Daar zal de efficiency van deze regel gevonden moeten worden.
              </p>
            </div>
          </div>

          <div className="flex items-start font-montserrat my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Jongeren</h3>
              <p>
                De leeftijd voor straffen van strafbare feiten, gepleegd door jongeren zal worden verlaagd naar 14 jaar. Hierdoor zal er een belangrijke rol worden weg gelegd voor ouders om toe te zien op de daden van hun kinderen.
                Aflossing van studieschulden van studenten worden (onder voorwaarden) fiscaal aftrekbaar. Door de fiscaal aftrekbare aflossing en rente te spreiden over een termijn van 15 tot 20 jaar bedragen de jaarlijkse kosten daarvan ca. € 1,0 miljard.
                Alle onderwijsvormen vanaf het voortgezet onderwijs vraag gestuurd organiseren.</p>
            </div>
          </div>
          <div className="flex items-start font-montserrat my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Huidige (midden) generatie</h3>
              <p>
                Voor de werkende midden generatie (die op veel fronten buiten toeslagen valt) zal de arbeidskorting fors worden verhoogd.
                Hypotheekrenteaftrek blijft onverminderd in stand.  Hiervan vindt geen afbouw meer plaats.</p>
            </div>
          </div>

          <div className="flex items-start font-medium my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Lokale overheden en lokale heffingen</h3>
              <p>
                Lagere overheden zijn autonoom in hun eigen beleid, binnen wettelijke grenzen.
                Wij willen naar gelijke kansen en regelgeving voor iedereen. In elke provincie en gemeenten dezelfde rechten en plichten. Daar mogen geen verschillen in zitten. Over welk beleid of welke uitvoeringsregels, heffingen etc. het ook gaat. Onroerendezaakbelastingen, afvalstoffenheffingen, rioolbelastingen, waterschapsbelasting en etc in alle gemeenten c.q. provincies gelijk, in elk geval geplafonneerd. Afwijkend beleid zal in rechte kunnen worden verworpen en in elk geval onderhevig aan te maken wetgeving.</p>
            </div>
          </div>

          <div className="flex items-start font-medium my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Softdrugs en rookbeleid</h3>
              <p>
              Het telen, verhandelen en gebruiken van softdrugs wordt (onder voorwaarden) niet meer strafbaar gesteld.
Het algehele rookverbod in onder andere de horeca en andere sectoren zal worden opgeheven tenzij de uitbater of instantie daar uitdrukkelijk niet voor kiest. Dit wordt een vrije keuze, daarom niet langer opgelegd van Rijkswege.
Preventie dient meer zichtbaar te worden. De accijnzen willen we beperken tot 5 % over de kleinhandelsprijs.
</p>
            </div>
          </div>


          <div className="flex items-start font-medium my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Afschaffing energiebelasting</h3>
              <p>
              Wij willen de energiebelasting afschaffen. Dit is een zinloze administratieve rompslomp die prijsverhogend werkt voor consumenten en bedrijven. Alleen prijsverhogende effecten leiden immers niet tot verduurzaming. Andere slimme oplossingen zullen die taakstelling dienen te bewerkstelligen. 
 </p>
            </div>
          </div>
          <div className="flex items-start font-medium my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Open en andere overheid</h3>
              <p>
              Wij streven naar een open, eerlijke en transparante overheid.
De uitvoering van wetten van vak ministeries zoals onder andere sociale zaken, financiën, onderwijs, cultuur zullen ondergebracht worden in een apart (bedrijfskundig) ministerie. Door deze afscheiding wordt de politieke angel uit de uitvoering gehaald. Dit is efficiënter, effectiever en voorkomt belangenverstrengeling en inmenging tussen beleid, politiek en uitvoering.
Ook lagere overheden zal deze andere inrichting worden verzocht om hun organisatie als zodanig in te richten c.q. worden opgelegd, daar waar nodig en/of noodzakelijk.
 </p>
            </div>
          </div>
          <div className="flex items-start font-medium my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Arbeidsmarktbeleid</h3>
              <p>
              Vanwege de arbeidstekorten achten wij een stimulans om parttimers te bewegen meer uren te werken zinvol maar dan ook belonen.
Iedereen die vanaf zijn 40ste levensjaar onafgebroken tenminste 32 uren per week deelneemt aan het arbeidsproces kan eerder met pensioen en AOW verkrijgen, anders dan bepaald in de huidige wet- en regelgeving. Voor (landurig) arbeidsongeschikten zal een aangepaste regeling bedacht moeten worden.
De uitkeringen in de zin van de Participatiewet willen we (voorwaardelijk) verhogen en omvormen tot een werkgelegenheidsstimuleringswet, zodat iedereen er weer bij hoort. 
</p>
            </div>
          </div>
          <div className="flex items-start font-medium my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">MKB (belastingen) en concurrentieposities</h3>
              <p>
              Een sterk MKB is de motor van onze Economie.
Het belastingstelsel zal worden herzien. Verdiend inkomen en daarmee samenhangend vermogen zal eenmalig en daarna niet langer worden belast. Ook niet in het erfrecht.
Ondernemersvrijstellingen zoals (ondernemers) aftrekposten en de MKB-vrijstelling zullen verder worden gestimuleerd aan de hand van maatschappelijke criteria en zullen worden aangepast aan maatschappelijke doelstellingen. Het MKB is immers de grootste werkgever van ons land. Geen overheidsinmenging meer in regels welke toezien op voorziene -of gewenste en/of verwachte globaliserende ontwikkelingen., zoals bijvoorbeeld CO2 uitstoot. Het is positiever te stimuleren gemaakte winsten te investeren (met investeringsaftrek) in vermindering van CO2 uitstoot in plaats van bureaucratische regels.
ZZP’ers blijven ZZP. Ondernemers blijven ondernemers. Het is en zal ieders vrije keuze blijven om hiervoor te kiezen. Geen overheidsinmenging in keuzes tussen ondernemers onderling. Geen AOV-verplichting (Arbeid Ongeschiktheid Verzekering). Te duur, te complex en onuitvoerbaar. Interessanter is het om ondernemers standaard een aftrekpost te verlenen om dit risico zelf op te kunnen vangen. Dit zou kunnen met verplichte storting op eigen geblokkeerde bankrekening die belastingvrij vrijvalt bij pensioen of gebruikt kan worden bij arbeidsongeschiktheid.
</p>
            </div>
          </div>
          <div className="flex items-start font-medium my-6 mr-10">
            <img src={HearthIcon} alt='' className="h-7 mr-4" />
            <div>
              <h3 className="font-semibold text-2xl">Media en journalistiek</h3>
              <p>
              Om nepnieuws, des- en/of misinformatie en of gestuurde verslaggeving te voorkomen gaan we naar bronvermelding in plaats van bronbescherming. Het niet vermelden van bronnen zal strafbaar worden gesteld. Daarbij geldt hetzelfde voor daarmee samenhangende ambtsmisdrijven.</p>
            </div>
          </div>
          </div>

        <div className="text-center mt-12" id="moreButton">
          <div onClick={OpenMore} className="font-medium text-2xl text-white bg-orange-600 px-4 py-2 shadow-lg hover:bg-orange-700 cursor-pointer">Bekijk alle standpunten</div>
        </div>
      </section>
    </>
  );
}