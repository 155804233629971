import { Route, Routes } from "react-router-dom"
import Homepage from "./pages/Homepage/Homepage"
import Standpuntenpage from "./pages/Standpuntenpage/Standpuntenpage";
import Membershippage from "./pages/Membershippage/Membershippage";
import Meedenkenpage from "./pages/Meedenkenpage/Meedenkenpage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/standpunten" element={<Standpuntenpage />} />
      <Route path="/meedenken" element={<Meedenkenpage />} />
      <Route path="/lidworden" element={<Membershippage />} />
   </Routes>
  );
}

export default App;
