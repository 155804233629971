import FacebookIcon from '../../assets/Facebook.svg'
import InstagramIcon from '../../assets/Instagram.svg'
import YoutubeIcon from '../../assets/Youtube.svg'
import TwitterIcon from '../../assets/Twitter.svg'



export default function Footer() {
    return (
        <>
            <section className="bg-blue-800 sectionSize">
                <div className="mb-4">
               
                </div>
                
                <div className="text-white font-montserrat text-sm">
                    © 2023 Ons DNA.
                </div>

                <div className="text-white font-montserrat text-sm text-center">
                    <br/>
                Democratisch Nederlands Appèl: Raadhuisplein 11, 5953 AL Reuver, Nederland
                <br/>
                DNApolitiek@gmail.com | KvK:88204170 | omzetbelastingnummer: NL864536902.B.01 | aanduiding DNA register Tweede Kamer der Staten-Generaal nr. 389
                </div>
            </section>
        </>
    );
}