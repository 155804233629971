import React, { useState, useEffect } from "react";
import './Slider.css';

const Slider = ({ images }) => {
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        const next = (current + 1) % images.length;
        const id = setTimeout(() => setCurrent(next), 3000);
        return () => clearTimeout(id);
    }, [current, images.length]);

    return (
        <div className="slider">
            {images.map((src, index) => (
                <div
                    key={index}
                    className={
                        index === current
                            ? "slide active"
                            : "slide"
                    }
                >
                    {index === current && (
                        <img src={src} alt="" />
                    )}
                </div>
            ))}
        </div>
    );
};

export default Slider;
