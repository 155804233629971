import Navbar from "../../components/Navbar/Navbar"
import Footer from "../../components/Footer/Footer"
import SectionTitle from "../../components/SectionTitle/SectionTitle"
export default function Meedenkenpage() {
    return(
        <>
            <Navbar />
            <section className="pt-40 justify-center text-center lg:px-48 md:px-12 px-4 bg-secondary">
                <SectionTitle title="Help ons denken" />
                <p>Wij zoeken (mee)denkers die kritisch zijn, echter wel de richting van DNA onderschrijven.<br/><br/>
                <div className="flex justify-center pb-12">
                <ul className="text-left ">
<li>1. Landelijke leden Raad van Toezicht (tactisch, bestuurlijk en strategisch)</li>
<li>2. Landelijke bestuursleden</li>
<li>3. Provinciale bestuursleden</li>
<li>4. Organisatie -en bedrijfsdeskundigen</li>
<li>5. Partijbureaumedewerkers</li>
<li>6. Marketingdeskundigen</li>
<li>7. ICT-deskundigen</li>
<li>8. Voor onze Denktanks (voorzitters, secretarissen en vooral ook meedenkers)</li>
<li>a. Staatsrecht</li>
<li>b. Europees recht</li>
<li>c. Fiscalisten en juristen</li>
<li>d. Financieel specialisten</li>
<li>e. Vastgoedontwikkelaars en bouwondernemers</li>
<li>f. Bouwkundigen</li>
<li>g. Zorgspecialisten (zorgsystemen en verzekeraars)</li>
<li>h. Zorgverleners (huisartsen, thuiszorg, mantelzorgers, gehandicaptenzorg, jeugdzorg etc.)</li>
<li>i. Onderwijsspecialisten</li>
<li>j. Economen</li>
<li>k. Gemeentelijke specialisten</li>
<li>l. Waterschap specialisten</li>
<li>m. Luchtvaart specialisten</li>
<li>n. Klimaat -en milieuspecialisten</li>
<li>o. MKB’ers</li>
<li>p. CEO’s</li>
<li>q. Pensioendeskundigen</li>
<li>r. Arbeidsrecht -en marktdeskundigen</li>
<li>s. Agrariërs</li>
<li>t. Bouwkundig personeel</li>
<li>u. Installateurs (o.a. over klimaat transitie, gas en elektriciteit)</li>
<li>v. Criminologen en juristen</li>
<li>w. Specialisten jeugd -en gehandicaptenzorg</li>
<li>x. Vissers</li>
<li>y. Horecaondernemers</li>
<li>z. Allen die we zijn vergeten op te schrijven</li>
</ul>
</div>
                </p>
            </section>
            <Footer />
        </>
    );
}