import Steps from '../../components/Steps/Steps'
import Hero from "../../components/Hero/Hero"
import Navbar from "../../components/Navbar/Navbar"
import Standpunten from '../../components/Standpunten/Standpunten';
import Footer from '../../components/Footer/Footer';

export default function Homepage() {
    return(
        <>
            <Navbar />
            <Hero />
            <Steps />
            <Standpunten />
            <Footer />
        </>
    );
}