import Navbar from "../../components/Navbar/Navbar"
import Footer from "../../components/Footer/Footer"
import ElftalImage from "../../assets/elftal.jpg"
import SectionTitle from "../../components/SectionTitle/SectionTitle"

export default function Membershippage() {
    return (
        <>
            <Navbar />
            <section className="pt-40 justify-center text-center lg:px-48 md:px-12 px-4 bg-secondary h-screen">
                <div className="flex flex-col justify-center text-center">
                    <SectionTitle title="Word Lid en draag bij aan het behoud van Ons DNA" />
                    <p>Lid worden en doneren kan door middel van een storting op de volgende rekening: <strong> <br/>DNA Debiteuren incasso NL31 INGB 0100 4195 85</strong></p>
                    <br/><br/><p>Bekijk <a href="#" className="underline">hier</a>  ook de lijst met ervaringsdeskundigen met welke wij graag in contact komen!</p>   

                </div>
            </section>
            <section>
         
            </section>
            <Footer />
        </>
    );
}