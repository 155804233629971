import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import SectionTitle from "../../components/SectionTitle/SectionTitle"
import HearthIcon from "../../assets/Heart.svg"

export default function Standpuntenpage() {
    return (
        <>
            <Navbar />
            <section className="sectionSize items-start pt-8 md:pt-36 text-black">
                <div className="mt-12 ">
                    <SectionTitle title="Standpunten" />
                </div>
            </section>
            <section className="sectionSize items-start pt-8 text-black">
            <div className="grid md:grid-cols-2 md:grid-rows-2 gap-y-12">

<div className="flex items-start font-medium my-6 mr-10">
  <img src={ HearthIcon } alt='' className="h-7 mr-4" />
  <div>
    <h3 className="font-semibold text-2xl">Beter politiek bestuur</h3>
    <p>
    Wij streven naar een open, eerlijke en transparante overheid. De uitvoering van wetten van vak ministeries zoals onder andere sociale zaken, financiën, onderwijs, cultuur zullen ondergebracht worden in een apart (bedrijfskundig) ministerie. Door deze afscheiding wordt de politieke angel uit de uitvoering gehaald. Dit is efficiënter, effectiever en voorkomt belangenverstrengeling en inmenging tussen beleid, politiek en uitvoering. Ook lagere overheden zal deze andere inrichting worden verzocht om hun organisatie als zodanig in te richten c.q. worden opgelegd, daar waar nodig en/of noodzakelijk.
    </p>
  </div>
</div>

<div className="flex items-start font-medium my-6 mr-10">
  <img src={ HearthIcon } alt='' className="h-7 mr-4" />
  <div>
    <h3 className="font-semibold text-2xl">Migratiestop</h3>
    <p>
    In onze visie zijn wij een tolerant en gastvrij land. Wij maken geen onderscheid tussen kleur of geloof, iedereen is ons even lief. Daar staat democratie voor. Maar de toestroom van allerlei soorten migranten naar ons land maakt dat ons land te vol is geworden en velen met ons DNA geen woonplek meer kunnen vinden. Onacceptabel!
    </p>
  </div>
</div>

<div className="flex items-start font-medium my-6 mr-10">
  <img src={ HearthIcon } alt='' className="h-7 mr-4" />
  <div>
    <h3 className="font-semibold text-2xl">Betrouwbare Overheid</h3>
    <p>
    Lagere overheden zijn autonoom in hun eigen beleid, binnen wettelijke grenzen.

Wij willen naar gelijke kansen en regelgeving voor iedereen. In elke provincie en gemeenten dezelfde rechten en plichten. Daar mogen geen verschillen in zitten. Over welk beleid of welke uitvoeringsregels, heffingen etc. het ook gaat. Onroerendezaakbelastingen, afvalstoffenheffingen, rioolbelastingen, waterschapsbelasting en etc in alle gemeenten c.q. provincies gelijk, in elk geval geplafonneerd. Afwijkend beleid zal in rechte kunnen worden verworpen en in elk geval onderhevig aan te maken wetgeving.
    </p>
  </div>
</div>

<div className="flex items-start font-montserrat my-6 mr-10">
  <img src={ HearthIcon } alt='' className="h-7 mr-4" />
  <div>
    <h3 className="font-semibold text-2xl">Zorg</h3>
    <p>
    De eigen bijdrage in de zorg (€ 385) zal worden afgeschaft. De administratieve -en regeldruk in de zorg zal naar beneden gaan (dus ook de premie). Bij volledig gebruik van het eigen risico zou dat ca. € 5,50 miljard kosten. Meer tijd voor handen aan het bed en voor de patiënt. Kosten van de zorg zullen worden genormeerd. Zowel voor de zorgverleners -verzekeraars als ook voor farmaceuten. Daar zal de efficiency van deze regel gevonden moeten worden.
    </p>
  </div>
</div>



</div>

            </section>
            <Footer />
        </>
    );
}