import underline from '../../assets/Underline1.svg'
import ImageTwo from '../../assets/2.jpg'
import ImageThree from '../../assets/3.jpg'
import ImageFour from '../../assets/4.jpg'
import ImageFive from '../../assets/5.jpg'
import ImageSix from '../../assets/6.jpg'
import ImageSeven from '../../assets/7.png'
import ImageNine from '../../assets/9.jpg'
import ImageTen from '../../assets/10.jpg'
import ImageEleven from '../../assets/11.jpg'
import ImageTwelve from '../../assets/12.jpg'
import ImageThirteen from '../../assets/13.jpg'
import Visie from '../../assets/OnsDnaVisie.pdf'
import Missie from '../../assets/MissieDna.pdf'
import Slider from '../Slider/Slider';

import './Hero.css'
const images = [
    ImageTwo,
    ImageThree,
    ImageFour,
    ImageFive,
    ImageSix,
    ImageSeven,
    ImageNine,
    ImageTen,
    ImageEleven,
    ImageTwelve,
    ImageThirteen
    // add other image imports here...
];

export default function Hero() {

    return (
        <>
            <section
                className="md:pt-64 md:pb-48 pb-24 pt-24 md:mt-0 min-h-full flex flex-col justify-center text-center md:text-left md:flex-row md:justify-between md:items-center lg:px-48 md:px-12 px-4 bg-secondary">
                <div className="md:flex-1 md:mr-10">
                    <h1 className="font-pt-serif text-5xl font-bold mb-7">
                        De wil van
                        <br />
                        <span
                            className="bg-underline1 bg-left-bottom bg-no-repeat pb-2 bg-100%  text-8xl"
                            style={
                                {
                                    backgroundImage: `url(${underline})`,
                                    backgroundPosition: "bottom"
                                }
                            }
                        >
                            <em>het volk</em>
                        </span>
                    </h1>
                    <p className="font-pt-serif font-normal mb-7">
                        Wij zijn een politieke beweging die dienstig wil zijn op basis van enerzijds "de wil van de bevolking" én anderzijds, vooral, "vóór de bevolking".

                        Wij hebben de naam DNA niet voor niets gekozen. DNA is erfelijk en wordt doorgegeven aan volgende generaties. Dat dient met verkregen trots, respect en eer en trouw gepaard te gaan.
                    </p>
                    <div className="font-montserrat flex flex-col gap-2 font-bold">
                        <a className="px-6 py-4 border-2 md:w-72 text-center border-black border-solid rounded-lg" href={Visie} target='_blank'>
                            Lees onze volledige visie
                        </a>
                        <a className="px-6 py-4 border-2 md:w-72 text-center border-black border-solid rounded-lg" href={Missie} target='_blank'>
                            Lees onze volledige missie
                        </a>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center md:flex md:justify-center md:items-center mt-8 md:mt-0 md:flex-1 hidden md:block">
                    <Slider images={images} />
                </div>
            </section>
            <div className="scroll-downs mx-auto mb-12">
                <div className="mousey">
                    <div className="scroller mx-auto"></div>
                </div>
            </div>
        </>
    );
}