import Underline from "../../assets/Underline2.svg"

export default function SectionTitle(props) {
    return (
            <div>
                <h2 className="secondaryTitle bg-100%"
                    style={{
                        background: `url(${Underline})`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat"
                    }}
                >{ props.title }</h2>
            </div>
    );
}