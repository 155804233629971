import Logo from '../../assets/logodna.svg'
import MenuIcon from '../../assets/Menu.svg'
import Cross from '../../assets/Cross.svg'
import React, { useState } from 'react';


export default function Navbar() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
      };

      const mobileMenuItems = [
        { label: 'Standpunten', url: '#standpunten' },
        { label: 'Meedenken', url: 'meedenken' },
        { label: 'Word lid!', url: 'lidworden' },
      ];

    return (
        <>
             <nav className="fixed flex justify-between py-6 w-full lg:px-48 md:px-12 px-4 content-center bg-orange-600 z-20 h-16 md:h-32">
        <div className="flex items-center">
          <a href='/'>
            <img src={Logo} alt="Logo" className="h-12 md:h-24 absolute top-2" />
          </a>
        </div>

        <div className="font-montserrat hidden md:flex items-center">
          <ul className="font-montserrat items-center hidden md:flex gap-4">
            <li className="mx-3">
              <a className="growing-underline" href="#standpunten">
                Standpunten
              </a>
            </li>
            <li className="growing-underline mx-3">
              <a href="meedenken">Meedenken</a>
            </li>
            <a href='lidworden' className="py-2 px-4 text-white bg-blue-600 rounded-3xl">
              Word lid!
            </a>
          </ul>
        </div>

        {/* Mobile Menu Icon */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? (
              <img src={Cross} alt="Close Menu" className="h-6 w-6" />
            ) : (
              <img src={MenuIcon} alt="Open Menu" className="h-6 w-6" />
            )}
          </button>
        </div>

      </nav>

     {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 opacity-90 z-50">
          <ul className="flex flex-col items-center justify-center h-full">
            {mobileMenuItems.map((item, index) => (
              <li key={index} className="my-4">
                <a
                  href={item.url}
                  className="text-white font-montserrat text-2xl"
                  onClick={toggleMobileMenu}
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
        </>
    );
}