import './Steps.css'

export default function Steps() {
    return (
        <>
            <section className="bg-red-700 text-white sectionSize mt-12 md:mt-2">
                <div>
                    <h2 className="secondaryTitle bg-underline2 bg-100%">Ons DNA plan</h2>
                </div>
                <div className="flex flex-col md:flex-row">
                    <div className="flex-1 mx-8 flex flex-col items-center my-4">
                        <div className="border-2 rounded-full bg-red-100 text-red-600 font-bold h-12 w-12 flex justify-center items-center mb-3">
                            1
                        </div>
                        <h3 className="font-montserrat font-medium text-xl mb-2">Samen Denken</h3>
                        <p className="text-center font-montserrat">
                            Eerst zullen we gelijkgestemden moeten samen brengen.
                        </p>
                    </div>
                    <div className="flex-1 mx-8 flex flex-col items-center my-4">
                        <div className="border-2 rounded-full bg-red-100 text-red-600 font-bold h-12 w-12 flex justify-center items-center mb-3">
                            2
                        </div>
                        <h3 className="font-montserrat font-medium text-xl mb-2">Samen groeien</h3>
                        <p className="text-center font-montserrat">
                            Daarna kunnen we samen groeien en de grootste partij worden.
                        </p>
                    </div>
                    <div className="flex-1 mx-8 flex flex-col items-center my-4">
                        <div className="border-2 rounded-full bg-red-100 text-red-600 font-bold h-12 w-12 flex justify-center items-center mb-3">
                            3
                        </div>
                        <h3 className="font-montserrat font-medium text-xl mb-2">Samen winnen</h3>
                        <p className="text-center font-montserrat">
                            Vervolgens zullen we een van de grootste worden in de Tweede Kamer en Nederland veranderen.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}